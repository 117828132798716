<template>
<span>

    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_foto" persistent max-width="600px">
                <v-card>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <img v-if="prod_foto_destaque" :src="`${base_url}upload/product_photo/g/${prod_foto_destaque}`" class="foto-ampliada">
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_foto = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <template>
        <v-layout row justify-end>
            <v-dialog v-model="dialog_editPrice" persistent max-width="600px" overlay-color="blue-grey darken-3" overlay-opacity="0.5">
                <v-card>
                    <v-card-title>
                        <span class="headline">Edição rápida Preço/Estoque</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <div class="input-v-money mb-5">
                                        <label for="">Valor</label>
                                        <money v-model="itemEdit.prod_valor_b2c" v-bind="money"></money>
                                    </div>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field v-model="itemEdit.prod_estoque" ref="stor_gf_it_qde" label="Estoque" required outlined=""></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-grey darken-1" @click="dialog_editPrice = false">Fechar</v-btn>
                        <v-btn color="light-green darken-3" @click="saveEditPrice">Salvar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </template>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Produtos</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="green darken-2" dark class="mb-1" @click.prevent="exportProducts">
                <v-icon dark>save_alt</v-icon> Exportar para Excel
            </v-btn>
            <v-btn color="blue-grey darken-2" dark class="mb-1 ml-2" @click.prevent="loadProducts">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'product.add'}">Adicionar Novo Produto</v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field xs6 v-model="search" append-icon="search" label="Localizar na Grid" outlined></v-text-field>
            <v-spacer></v-spacer>
            <!--<search-form @pesquisarDados="filter"></search-form>-->
            <v-flex xs6>
                <v-text-field v-model="busca" append-icon="search" label="Localizar no Banco de Dados" outlined @keyup.13.prevent="loadProducts"></v-text-field>
            </v-flex>
        </v-card-title>
        <v-data-table :headers="headers" :items="product.data" :search="search" :rowsPerPageItems="rowsPerPageItems" hide-default-footer>

            <template v-slot:[`item.prod_foto_destaque`]="{ item }">
                <img v-if="item.prod_foto_destaque" :src="`${base_url}upload/product_photo/q/${item.prod_foto_destaque}?${uuid}`" class="avatar" @click="ampliarFoto(item.prod_foto_destaque)">
                <img v-if="!item.prod_foto_destaque" :src="`${base_url}upload/imagem.png`" class="avatar">
            </template>

            <template v-slot:[`item.subcategoria.prod_sc_nome`]="{ item }">
                {{ item.subcategoria.prod_sc_nome }}
            </template>

            <template v-slot:[`item.prod_valor_b2c`]="{ item }">
                {{ item.prod_valor_b2c }}
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editPrice(item)" v-on="on">
                            <v-icon small dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Edição Rápida</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.prod_show_site`]="{ item }">
                <v-switch color="lime" v-model="item.prod_show_site" @change="onChangeEventHandlerShowSite(item.prod_id, $event)" class="my-switch"></v-switch>
            </template>

            <template v-slot:[`item.prod_show_preco`]="{ item }">
                <v-switch color="lime" v-model="item.prod_show_preco" @change="onChangeEventHandlerShowPreco(item.prod_id, $event)" class="my-switch"></v-switch>
            </template>

            <!--<template v-slot:[`item.prod_status`]="{ item }">
                <v-switch color="lime" v-model="item.prod_status" @change="onChangeEventHandlerStatus(item.prod_id, $event)" class="my-switch"></v-switch>
            </template>-->

            <template v-slot:[`item.prod_destaque_home`]="{ item }">
                <v-switch color="lime" v-model="item.prod_destaque_home" @change="onChangeEventHandlerDestaqueHome(item.prod_id, $event)" class="my-switch"></v-switch>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn small icon color="blue lighten-1" @click.prevent="editProduct(item)" v-on="on">
                            <v-icon small dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn small icon color="red lighten-1" @click.prevent="deleteProduct(item)" v-on="on">
                            <v-icon small dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn small icon color="lime lighten-1" @click.prevent="detailProduct(item)" v-on="on">
                            <v-icon small dark class="mdi mdi-reorder-horizontal"></v-icon>
                        </v-btn>
                    </template>
                    <span>Detalhes</span>
                </v-tooltip>

                <v-tooltip top color="teal darken-1 black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn small icon color="teal darken-1" @click.prevent="photoProduct(item)" v-on="on">
                            <v-icon small dark class="mdi mdi-image-plus"></v-icon>
                        </v-btn>
                    </template>
                    <span>Fotos</span>
                </v-tooltip>

                <v-tooltip top color="purple accent-2 black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn small icon color="purple accent-2" @click.prevent="colorProduct(item)" v-on="on">
                            <v-icon small dark class="mdi mdi-palette"></v-icon>
                        </v-btn>
                    </template>
                    <span>Cores</span>
                </v-tooltip>

                <v-tooltip top color="orange darken-1 black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn small icon color="orange darken-1" @click.prevent="fileProduct(item)" v-on="on">
                            <v-icon small dark class="mdi mdi-file-upload"></v-icon>
                        </v-btn>
                    </template>
                    <span>Upload PDFs</span>
                </v-tooltip>

            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Voce procurou por "{{ search }}" nenhum item localizado.
                </v-alert>
            </template>
        </v-data-table>

        <pagination @loadItems="loadProducts" :items="product"></pagination>

    </v-card>

</span>
</template>

<script>
import {
    Money
} from 'v-money'
import {
    URL_BASE
} from '../../../config/configs'
import SearchForm from '../partials/SearchComponent'
import Pagination from '../partials/PaginationComponent'
import {
    uuid
} from "vue-uuid";

export default {
    name: "ProductsComponent",
    created() {
        this.loadProducts(1)
    },
    computed: {
        params() {
            return {
                search: '',
                page: this.product.current_page
            }
        }
    },
    data() {
        return {
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false /* doesn't work with directive */
            },
            prod_foto_destaque: null,
            dialog_foto: false,
            dialog_editPrice: false,
            search: '',
            busca: '',
            rowsPerPageItems: [10],
            headers: [{
                    text: 'Imagem',
                    align: 'left',
                    sortable: false,
                    value: 'prod_foto_destaque'
                },
                {
                    sortable: false,
                    text: 'Código',
                    value: 'prod_codigo'
                },
                {
                    sortable: false,
                    text: 'Nome',
                    value: 'prod_full_name'
                },
                {
                    sortable: false,
                    text: 'Subcategoria',
                    value: 'subcategoria.prod_sc_nome'
                },
                {
                    sortable: false,
                    align: 'right',
                    text: 'Preço',
                    value: 'prod_valor_b2c'
                },
                {
                    sortable: false,
                    align: 'center',
                    text: 'Estoque',
                    value: 'prod_estoque'
                },
                {
                    sortable: false,
                    align: 'right',
                    text: 'Preço Promoção',
                    value: 'prod_valor_promocao'
                },
                {
                    sortable: false,
                    align: 'left',
                    text: 'Mostrar no Site',
                    value: 'prod_show_site'
                },
                {
                    sortable: false,
                    align: 'left',
                    text: 'Mostrar Preço',
                    value: 'prod_show_preco'
                },
                {
                    sortable: false,
                    align: 'left',
                    text: 'Destaque',
                    value: 'prod_destaque_home'
                },
                {
                    text: 'Ações',
                    align: 'center',
                    sortable: false,
                    value: 'acoes'
                }
            ],
            product: [],
            itemEdit: [],
            base_url: URL_BASE,
            uuid: uuid.v1()
        }
    },
    methods: {
        ampliarFoto(foto) {
            this.prod_foto_destaque = foto;
            this.dialog_foto = true;
        },
        editPrice(item) {
            this.itemEdit.prod_id = item.prod_id;
            this.itemEdit.prod_valor_b2c = item.prod_valor_b2c;
            this.itemEdit.prod_estoque = item.prod_estoque;
            this.dialog_editPrice = true;
        },
        saveEditPrice() {
            if ((this.itemEdit.prod_valor_b2c === "") || (this.itemEdit.prod_estoque === "")) {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: "Atenção",
                    text: "Campos Valor e Estoque são requerido",
                    showConfirmButton: true,
                    timer: 6000
                });
            } else {
                this.$store
                    .dispatch("editProductPrice", {
                        prod_id: this.itemEdit.prod_id,
                        prod_valor_b2c: this.itemEdit.prod_valor_b2c,
                        prod_estoque: this.itemEdit.prod_estoque,
                    })
                    .then(response => {
                        if (response.data.success) {
                            this.dialog_editPrice = false;
                            this.loadProducts(this.product.current_page);
                        } else {
                            this.$swal({
                                position: "center",
                                icon: "warning",
                                title: "Atenção",
                                text: response.error,
                                showConfirmButton: true,
                                timer: 6000
                            });
                        }
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: "Erro",
                            text: "Opss! Algo deu errado",
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response;
                    });
            }
        },
        loadProducts(page) {
            this.$store.dispatch('loadProducts', {
                    search: this.busca,
                    page: page
                })
                .then(response => {
                    this.product = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
        exportProducts(page) {
            this.$store.dispatch('exportProducts')
                .then(response => {
                    this.openExcel(response.data)
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Erro ao exportar dados',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
        editProduct(item) {
            this.$router.push({
                name: 'product.edit',
                params: {
                    prod_id: item.prod_id
                }
            });
        },

        detailProduct(item) {
            this.$router.push({
                name: 'product.detail',
                params: {
                    prod_id: item.prod_id,
                }
            });
        },
        photoProduct(item) {
            this.$router.push({
                name: 'product.foto',
                params: {
                    prod_id: item.prod_id
                }
            });
        },
        colorProduct(item) {
            this.$router.push({
                name: 'product.color',
                params: {
                    prod_id: item.prod_id
                }
            });
        },
        fileProduct(item) {
            this.$router.push({
                name: 'product.file',
                params: {
                    prod_id: item.prod_id
                }
            });
        },
        deleteProduct(item) {

            this.$swal({
                title: 'Você tem certeza?',
                text: "Você não poderá reverter isso",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#D32F2F',
                cancelButtonColor: '#1976D2',
                confirmButtonText: 'Sim, Exclua!'
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch('destroyProduct', item.prod_id)
                        .then(response => {
                            if (response.data.success) {
                                this.$swal({
                                    position: "center",
                                    icon: "success",
                                    title: 'Sucesso',
                                    text: 'Dados excluídos com sucesso',
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                                this.loadProducts(1);
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "warning",
                                    title: 'Atenção!',
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: 'Opssss!',
                                text: error.data.message,
                                showConfirmButton: true,
                                timer: 6000
                            });
                        })
                }
            })

        },
        onChangeEventHandlerStatus(prod_id, value) {
            this.$store.dispatch('updateProductStatus', {
                    prod_id: prod_id,
                    prod_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: 'Sucesso',
                        text: 'Status do Produto atualizado com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Opssss!',
                        text: 'Erro ao atualizar status do Produto',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
        onChangeEventHandlerDestaqueHome(prod_id, value) {
            this.$store.dispatch('updateProductDestaqueHome', {
                    prod_id: prod_id,
                    prod_destaque_home: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: 'Sucesso',
                        text: 'Destaque do Produto na Home atualizado com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Opssss!',
                        text: 'Erro ao atualizar destaque do Produto',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
        onChangeEventHandlerShowSite(prod_id, value) {
            this.$store.dispatch('updateProductShowSite', {
                    prod_id: prod_id,
                    prod_show_site: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: 'Sucesso',
                        text: 'Atualizado com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Opssss!',
                        text: 'Erro ao atualizar',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
        onChangeEventHandlerShowPreco(prod_id, value) {
            this.$store.dispatch('updateProductShowPreco', {
                    prod_id: prod_id,
                    prod_show_preco: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: 'Sucesso',
                        text: 'Atualizado com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Opssss!',
                        text: 'Erro ao atualizar',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
        filter(filter) {
            this.params.search = filter;
            this.loadProducts(1)
        },
        openExcel(path) {
            javascipt:window.open(this.base_url + path, '_blank')
        }
    },
    components: {
        SearchForm: SearchForm,
        Pagination: Pagination,
        Money
    }

};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}

.foto-ampliada {
    max-width: 100%
}

.input-v-money {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    border-radius: 4px;
    padding: 6px 12px;
}

.input-v-money>label {
    color: rgba(255, 255, 255, 0.3);
    font-size: .8rem;
}

.input-v-money>input {
    color: #FFF;
}
</style>
